<template>
  <div class="page-customer">
    <div class="card card-custom card-stretch gutter-b customer-container">
      <!--begin::Header-->
      <div class="card-header">
        <!--begin::card-title-->
        <div class="card-title">
          <h3 class="card-label">
            <div class="d-flex align-items-center">
              <span class="title font-weight-bold text-dark mr-3">FAQ</span>
              <span class="subtitle font-weight-normal font-size-sm text-muted">{{i18n.useQuickFaq}}</span>
            </div>
          </h3>
        </div>
        <!--end::card-title-->
        <!--begin::card-search-->
        <div class="card-search">
          <!--begin::search-group(desktop)-->
          <div class="d-desktop search-group">
            <div class="form-group">
              <div class="input-icon">
                <input type="text" class="form-control rounded-pill" :placeholder="i18n.searchPlaceholder"/>
                <span><i class="flaticon2-search-1 icon-md"></i></span>
              </div>
              <b-button variant="dark" size="md" class="rounded-pill ml-2 btn-search">
                <span class="text font-size-lg font-weight-bold">{{i18n.search}}</span>
              </b-button>
            </div>
          </div>
          <!--begin::search-group(mobile)-->
          <div class="d-tablet search-group-sm">
            <!--begin::search button-->
            <b-button id="my-button" class="btn-icon rounded-pill">
              <i class="flaticon2-search-1 icon-md"></i>
            </b-button>
            <!--end::search button-->
            <!--begin::search popover-->
            <b-popover target="my-button" custom-class="my-popover-class" placement="left">
              <div class="quick-search quick-search-dropdown" id="kt_quick_search_dropdown">
                <!--begin:Form-->
                <form method="get" class="quick-search-form">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="flaticon2-search-1 icon-md"></i>
                      </span>
                    </div>
                    <input type="text" class="form-control" :placeholder="i18n.searchPlaceholder">
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="quick-search-close ki ki-close icon-sm text-muted"></i>
                      </span>
                    </div>
                  </div>
                </form>
                <!--end::Form-->
                <!--begin::Scroll-->
                <div class="quick-search-wrapper scroll" data-scroll="true" data-height="325" data-mobile-height="200" style="height: 200px; overflow: auto;"></div>
                <!--end::Scroll-->
              </div>
            </b-popover>
            <!--end::search popover-->
          </div>
        </div>
        <!--end::card-search-->
        <!--begin::card-toolbar-->
        <div class="card-toolbar">
          <router-link
              to="customer"
              v-slot="{ href, navigate }"
          >
            <b-button variant="primary" class="rounded-pill btn-toolbar" @click="navigate">
              <span class="svg-icon svg-icon-sm svg-icon-white">
                <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Send.svg" />
              </span>
              <span class="text font-size-h6 font-weight-bold">{{i18n.inquiry}}</span>
            </b-button>
          </router-link>
        </div>
        <!--end::card-toolbar-->
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body">
        <div class="accordion" role="tablist">
          <!--begin::accordion-item-->
          <div v-for="(item, idx) in items" :key="idx" class="accordion-item">
            <!--begin::accordion-header-->
            <div header-tag="header" role="tab">
              <div v-b-toggle="`accordion-${idx}`" variant="info" class="accordion-header">
                <div class="header-label">
                  <span class="font-size-h5">Q</span>
                </div>
                <div class="header-category">
                  <span class="font-size-lg font-weight-bold">{{ item.faqGb }}</span>
                </div>
                <div class="header-subject">
                  <span class="font-size-lg">{{ item.title }}</span>
                </div>
                <div class="header-icon">
                  <b-button variant="light" size="sm" class="btn-icon">
                    <span class="svg-icon svg-icon-sm svg-icon-dark">
                      <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Navigation/Angle-down.svg" />
                    </span>
                  </b-button>
                </div>
              </div>
            </div>
            <!--begin::accordion-panel-->
            <b-collapse :id="`accordion-${idx}`" visible accordion="my-accordion" role="tabpanel">
              <div class="accordion-content bg-light">
                <div class="content-label">
                  <span class="font-size-h5">A</span>
                </div>
                <div class="content-body">
                  <span class="text font-size-lg">{{ item.content }}</span>
                </div>
              </div>
            </b-collapse>
          </div>
          <!--end::accordion-item-->
          <!--begin::accordion-item-->
<!--          <div class="accordion-item">-->
<!--            &lt;!&ndash;begin::accordion-header&ndash;&gt;-->
<!--            <div header-tag="header" role="tab">-->
<!--              <div v-b-toggle.accordion-2 variant="info" class="accordion-header">-->
<!--                <div class="header-label">-->
<!--                  <span class="font-size-h5">Q</span>-->
<!--                </div>-->
<!--                <div class="header-category">-->
<!--                  <span class="font-size-lg font-weight-bold">환경제어기</span>-->
<!--                </div>-->
<!--                <div class="header-subject">-->
<!--                  <span class="font-size-lg">업무를 위해 환경을 제어하려면 어떻게 해야할지 궁금합니다.</span>-->
<!--                </div>-->
<!--                <div class="header-icon">-->
<!--                  <b-button variant="light" size="sm" class="btn-icon">-->
<!--                    <span class="svg-icon svg-icon-sm svg-icon-dark">-->
<!--                      <inline-svg src="media/svg/icons/Navigation/Angle-down.svg" />-->
<!--                    </span>-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;begin::accordion-panel&ndash;&gt;-->
<!--            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">-->
<!--              <div class="accordion-content bg-light">-->
<!--                <div class="content-label">-->
<!--                  <span class="font-size-h5">A</span>-->
<!--                </div>-->
<!--                <div class="content-body">-->
<!--                  <span class="text font-size-lg">동료와 함께 업무 협의를 위해 BP를 방문하여 BP의 로고가 새겨진 손목시계를 기념품으로 받았습니다. 받은 손목시계가 상당히 고급스러워 부담스럽다는 생각을 떨쳐 버릴 수가 없었습니다. 이 같은 경우에는 어떻게 처리하는 것이 좋을까요? BP의 로고나 명칭이 표시되어 있으며, 그 가격이 사회에서 인정되는 간소한 수준을 넘지 않는 물품은 받아도 괜찮습니다. 다만, 받은 물품이 BP의 로고나 명칭 표시 여부를 불문하고 공정한 업무수행에 지장을 초래할 수 있을 정도로 부담스러운 것이라면 윤리상담센터에 접수하여 주시기 바랍니다. 접수된 물품은 바자회 등을 통하여 판매하고 수익금은 결연단체에 기부하고 있습니다.</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-collapse>-->
<!--          </div>-->
<!--          &lt;!&ndash;end::accordion-item&ndash;&gt;-->
<!--          &lt;!&ndash;begin::accordion-item&ndash;&gt;-->
<!--          <div class="accordion-item">-->
<!--            &lt;!&ndash;begin::accordion-header&ndash;&gt;-->
<!--            <div header-tag="header" role="tab">-->
<!--              <div v-b-toggle.accordion-3 variant="info" class="accordion-header">-->
<!--                <div class="header-label">-->
<!--                  <span class="font-size-h5">Q</span>-->
<!--                </div>-->
<!--                <div class="header-category">-->
<!--                  <span class="font-size-lg font-weight-bold">환경제어기</span>-->
<!--                </div>-->
<!--                <div class="header-subject">-->
<!--                  <span class="font-size-lg">습도는 어떤 부분에서 확인이 가능한가요?</span>-->
<!--                </div>-->
<!--                <div class="header-icon">-->
<!--                  <b-button variant="light" size="sm" class="btn-icon">-->
<!--                    <span class="svg-icon svg-icon-sm svg-icon-dark">-->
<!--                      <inline-svg src="media/svg/icons/Navigation/Angle-down.svg" />-->
<!--                    </span>-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;begin::accordion-panel&ndash;&gt;-->
<!--            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">-->
<!--              <div class="accordion-content bg-light">-->
<!--                <div class="content-label">-->
<!--                  <span class="font-size-h5">A</span>-->
<!--                </div>-->
<!--                <div class="content-body">-->
<!--                  <span class="text font-size-lg">동료와 함께 업무 협의를 위해 BP를 방문하여 BP의 로고가 새겨진 손목시계를 기념품으로 받았습니다. 받은 손목시계가 상당히 고급스러워 부담스럽다는 생각을 떨쳐 버릴 수가 없었습니다. 이 같은 경우에는 어떻게 처리하는 것이 좋을까요? BP의 로고나 명칭이 표시되어 있으며, 그 가격이 사회에서 인정되는 간소한 수준을 넘지 않는 물품은 받아도 괜찮습니다. 다만, 받은 물품이 BP의 로고나 명칭 표시 여부를 불문하고 공정한 업무수행에 지장을 초래할 수 있을 정도로 부담스러운 것이라면 윤리상담센터에 접수하여 주시기 바랍니다. 접수된 물품은 바자회 등을 통하여 판매하고 수익금은 결연단체에 기부하고 있습니다.</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-collapse>-->
<!--          </div>-->
<!--          &lt;!&ndash;end::accordion-item&ndash;&gt;-->
<!--          &lt;!&ndash;begin::accordion-item&ndash;&gt;-->
<!--          <div class="accordion-item">-->
<!--            &lt;!&ndash;begin::accordion-header&ndash;&gt;-->
<!--            <div header-tag="header" role="tab">-->
<!--              <div v-b-toggle.accordion-4 variant="info" class="accordion-header">-->
<!--                <div class="header-label">-->
<!--                  <span class="font-size-h5">Q</span>-->
<!--                </div>-->
<!--                <div class="header-category">-->
<!--                  <span class="font-size-lg font-weight-bold">환경제어기</span>-->
<!--                </div>-->
<!--                <div class="header-subject">-->
<!--                  <span class="font-size-lg">온도 조절을 하기 위해서는 어떤 부분을 조정해야할까요?</span>-->
<!--                </div>-->
<!--                <div class="header-icon">-->
<!--                  <b-button variant="light" size="sm" class="btn-icon">-->
<!--                    <span class="svg-icon svg-icon-sm svg-icon-dark">-->
<!--                      <inline-svg src="media/svg/icons/Navigation/Angle-down.svg" />-->
<!--                    </span>-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;begin::accordion-panel&ndash;&gt;-->
<!--            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">-->
<!--              <div class="accordion-content bg-light">-->
<!--                <div class="content-label">-->
<!--                  <span class="font-size-h5">A</span>-->
<!--                </div>-->
<!--                <div class="content-body">-->
<!--                  <span class="text font-size-lg">동료와 함께 업무 협의를 위해 BP를 방문하여 BP의 로고가 새겨진 손목시계를 기념품으로 받았습니다. 받은 손목시계가 상당히 고급스러워 부담스럽다는 생각을 떨쳐 버릴 수가 없었습니다. 이 같은 경우에는 어떻게 처리하는 것이 좋을까요? BP의 로고나 명칭이 표시되어 있으며, 그 가격이 사회에서 인정되는 간소한 수준을 넘지 않는 물품은 받아도 괜찮습니다. 다만, 받은 물품이 BP의 로고나 명칭 표시 여부를 불문하고 공정한 업무수행에 지장을 초래할 수 있을 정도로 부담스러운 것이라면 윤리상담센터에 접수하여 주시기 바랍니다. 접수된 물품은 바자회 등을 통하여 판매하고 수익금은 결연단체에 기부하고 있습니다.</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-collapse>-->
<!--          </div>-->
<!--          &lt;!&ndash;end::accordion-item&ndash;&gt;-->
<!--          &lt;!&ndash;begin::accordion-item&ndash;&gt;-->
<!--          <div class="accordion-item">-->
<!--            &lt;!&ndash;begin::accordion-header&ndash;&gt;-->
<!--            <div header-tag="header" role="tab">-->
<!--              <div v-b-toggle.accordion-5 variant="info" class="accordion-header">-->
<!--                <div class="header-label">-->
<!--                  <span class="font-size-h5">Q</span>-->
<!--                </div>-->
<!--                <div class="header-category">-->
<!--                  <span class="font-size-lg font-weight-bold">환경제어기</span>-->
<!--                </div>-->
<!--                <div class="header-subject">-->
<!--                  <span class="font-size-lg">온도 조절을 하기 위해서는 어떤 부분을 조정해야할까요?</span>-->
<!--                </div>-->
<!--                <div class="header-icon">-->
<!--                  <b-button variant="light" size="sm" class="btn-icon">-->
<!--                    <span class="svg-icon svg-icon-sm svg-icon-dark">-->
<!--                      <inline-svg src="media/svg/icons/Navigation/Angle-down.svg" />-->
<!--                    </span>-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;begin::accordion-panel&ndash;&gt;-->
<!--            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">-->
<!--              <div class="accordion-content bg-light">-->
<!--                <div class="content-label">-->
<!--                  <span class="font-size-h5">A</span>-->
<!--                </div>-->
<!--                <div class="content-body">-->
<!--                  <span class="text font-size-lg">동료와 함께 업무 협의를 위해 BP를 방문하여 BP의 로고가 새겨진 손목시계를 기념품으로 받았습니다. 받은 손목시계가 상당히 고급스러워 부담스럽다는 생각을 떨쳐 버릴 수가 없었습니다. 이 같은 경우에는 어떻게 처리하는 것이 좋을까요? BP의 로고나 명칭이 표시되어 있으며, 그 가격이 사회에서 인정되는 간소한 수준을 넘지 않는 물품은 받아도 괜찮습니다. 다만, 받은 물품이 BP의 로고나 명칭 표시 여부를 불문하고 공정한 업무수행에 지장을 초래할 수 있을 정도로 부담스러운 것이라면 윤리상담센터에 접수하여 주시기 바랍니다. 접수된 물품은 바자회 등을 통하여 판매하고 수익금은 결연단체에 기부하고 있습니다.</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-collapse>-->
<!--          </div>-->

          <!--end::accordion-item-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>
<style scoped lang="scss">
.card-header {position:relative}
.card-toolbar {
  .btn-toolbar {width:10.308rem;height:4.462rem}
}
.card-search {
  position:absolute;left:50%;top:50%;display:flex;align-items:center; transform:translateX(-50%) translateY(-50%);
  .form-group {display:flex;margin-bottom:0;}
  .input-icon {}
  .input-icon .form-control {width:27rem; height:4.462rem; padding-left:calc(3.5em + 1.3rem + 2px);}
  .input-icon span {width:calc(3.5em + 1.3rem + 2px);}
  .btn-search {width:6rem;height:4.462rem}

  .search-group-sm {position:relative;}
}
.accordion-header {
  display:flex; padding:clamp(20px, 3vw, 30px); min-width:0; align-items:center;
  [class^="header-"] {line-height:1.6;}
  .header-label {position:relative; flex:0 0 30px; width:20px; text-align:center;}
  .header-subject {flex:1 1 auto;padding-right:10px}
  .header-category {flex:0 0 clamp(90px, 20%, 200px); width:clamp(90px, 20%, 200px); text-align:center;}
  .header-icon {flex:0 0 30px;margin-top:-4px;}
  .header-icon svg {transform:rotate(-180deg);}

  &.collapsed{
    border-bottom:1px solid #ebebeb;
    /*.header-subject {text-overflow:ellipsis; white-space:nowrap; overflow:hidden;}*/
    .header-icon svg {transform:rotate(0);}
  }
}

.accordion-item:last-child {
  .accordion-header.collapsed {border-bottom:0;}
}

.accordion-content {
  display:flex; padding:clamp(20px, 3vw, 30px) clamp(20px, 3vw, 30px) clamp(30px, 3vw, 50px);
  .content-label {position:relative; top:2px; flex:0 0 30px; width:20px; text-align:center; color:#6a00ff}
  .content-body {line-height:1; padding-left:clamp(90px, 20%, 200px);}
  .text {color:#444; line-height:2}
}

.d-desktop {display:block}
.d-tablet {display:none}
.popover {
  .arrow {display:none!important}
  .popover-body {padding:0 !important}
}
.quick-search.quick-search-dropdown {padding:.4rem !important}
@media screen and (max-width: 1399.98px) {
  .card.card-custom > .card-header .card-search {}
  .card.card-custom > .card-header .card-title .card-label .d-flex {display:block !important}
  .card.card-custom > .card-header .card-title .card-label .subtitle {display:none}
  .card-toolbar .btn-toolbar .font-size-h6 {font-size:14px !important}
  .card-search {
    .input-icon .form-control {width:18rem; height:3.2rem; padding-left:calc(2.5em + 1.3rem + 2px);}
    .input-icon span {width:calc(2.5em + 1.3rem + 2px);}
    .btn-search {width:5rem;height:3.2rem}
    .btn-search .text {font-size:14px !important  }
  }
  .card-toolbar {
    .btn-toolbar {width:9.308rem;height:3.2rem}
  }
}

@media screen and (max-width: 991.98px) {
  .d-desktop {display:none}
  .d-tablet {display:block}
  .card.card-custom > .card-header {flex-wrap:wrap;padding-top:2rem;padding-bottom:2rem}
  .card.card-custom > .card-header .card-search {left:auto;right:131px}
  .card.card-custom > .card-body, .card-body {padding-top:1rem}
  .header-label, .content-label {display:none!important}
  .accordion-header .header-category {display:none;text-align:left;padding-left:0;width:auto;padding-right:15px;flex:0 0 auto}
  .accordion-header .header-subject {padding-left:0}
  .accordion-content .content-body {padding-left:0}
  .accordion-content .text {font-size:13px;line-height:22px;}
}
</style>
<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_GET_FAQS} from '@/core/services/variable';
import {getItems, lengthCheck} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Faq",
  beforeMount() {
    this.getFaqs();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "FAQ", icon: 'flaticon2-chat' }]);
    window.scrollTo(0,0);
  },
  data() {
    return {
      i18n:{
        useQuickFaq: i18n.t('faq.useQuickFaq'),
        searchPlaceholder: i18n.t('faq.searchPlaceholder'),
        search: i18n.t('faq.search'),
        inquiry: i18n.t('faq.inquiry'),

      },
      items: [],
    }
  },
  methods: {
    getFaqs(){
      this.$store.dispatch(ACT_GET_FAQS, {})
      .then(resp => {
        if(lengthCheck(resp)) this.items = getItems(resp);
      }).catch(e => {
        console.error(e);
      })
    }
  }
}
</script>
